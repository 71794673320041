var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('b-card', [_c('validation-observer', {
    ref: "pilihSekolah",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "text-center",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.formSubmitted($event);
            }
          }
        }, [_c('validation-provider', {
          staticClass: "mt-4",
          attrs: {
            "name": "Sekolah",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('span', {
                staticClass: "mb-3"
              }, [_c('strong', [_vm._v("Halo " + _vm._s(_vm.user.name))]), _vm._v(", Selamat datang di Tryout " + _vm._s(_vm.tryoutDetail.name) + " Silakan pilih sekolah kedinasan tujuan anda terlebih dahulu sebelum memulai tryout")]), _c('br'), _c('br'), _c('center', [_c('b-form-group', {
                staticClass: "w-100",
                attrs: {
                  "state": errors.length > 0 ? false : null
                }
              }, [_c('v-select', {
                staticClass: "d-flex flex-column justify-content-center",
                staticStyle: {
                  "width": "50%"
                },
                attrs: {
                  "id": "sekolah",
                  "options": _vm.sekolahName,
                  "label": "text",
                  "placeholder": "Pilih Sekolah"
                },
                model: {
                  value: _vm.pilihSekolah,
                  callback: function callback($$v) {
                    _vm.pilihSekolah = $$v;
                  },
                  expression: "pilihSekolah"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": errors.length > 0 ? false : null
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)], 1)];
            }
          }], null, true)
        }), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary",
            "disabled": invalid
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }])
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }